import { Box, Typography, CardContent, Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStyles } from '../../styles/styles';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/Check';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PlaceholderImage from '../../assets/images/placeholder-project.svg';
import LinkTag from '@mui/material/Link';
import { formateBudgetRange } from '../../helpers/Helpers';
import { format } from '../../utils/DateUtil';

export const Project = ({ ...props }) => {
  const { record, reloadParams } = props;
  const classes = useStyles();
  const [displayName, setDisplayName] = useState<string | undefined>('');

  useEffect(() => {
    const companyDisplayName = record.company_display_name;
    const hiringManagerDisplayName = record.hiring_manager_display_name;

    if (companyDisplayName && hiringManagerDisplayName) {
      setDisplayName(`${companyDisplayName}, ${hiringManagerDisplayName}`);
    } else if (companyDisplayName) {
      setDisplayName(companyDisplayName);
    } else if (hiringManagerDisplayName) {
      setDisplayName(hiringManagerDisplayName);
    } else {
      setDisplayName(record.hiring_manager);
    }
  }, [record.company_display_name, record.hiring_manager_display_name, record.hiring_manager]);

  const onJobClick = (id: string) => {
    const serializedParams = JSON.stringify(reloadParams);
    window.localStorage.setItem('params', serializedParams);
    window.location.href = `/careers/experfy/opportunities/${id}/overview`;
  };

  return (
    <>
      <CardContent>
        <Box className={classes.jobCardThumb}>
          <Box className={classes.jobCardThumbInner}>
            {record.project_thumbnail ? (
              <>
                <Box className={classes.jobCardThumbImage} sx={{ backgroundImage: `url(${record.project_thumbnail})` }}></Box>
              </>
            ) : (
              <>
                <Box className={classes.jobCardThumbImage} sx={{ backgroundImage: `url(${PlaceholderImage})`, backgroundSize: 'contain' }}></Box>
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.jobCardInfo}>
          <Typography className={classes.jobCardTitle} variant='h3'>
            <LinkTag onClick={() => onJobClick(record._id)}>{record.job_title}</LinkTag>
          </Typography>
          <Stack className={classes.jobCardOrigin} direction='row'>
            {displayName?.trim() !== '' && <Typography className={`${classes.jobCardOrigin} hiringManager`}>{displayName}</Typography>}
            <Typography>posted {format(record.created_at, 'MMM DD, YYYY')}</Typography>
          </Stack>
          <Box className={classes.jobCardSumary}>
            <div dangerouslySetInnerHTML={{ __html: record.description ?? '' }}></div>
          </Box>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box className={classes.jobCardFeatures}>
          {record.ideal_candidate_location && (
            <Typography>
              <PlaceIcon />
              {record.ideal_candidate_location}
            </Typography>
          )}
          {record.open_to_remote === true && (
            <Typography>
              <CheckIcon />
              Open to Remote
            </Typography>
          )}
          {record.job_type && (
            <Typography>
              <WorkIcon />
              {record.job_type}
            </Typography>
          )}
          {/* {(record.min_rate || record.max_rate) && (
            <Typography>
              <MonetizationOnIcon />
              {formateBudgetRange(record.remunerations, record.min_rate, record.max_rate)}
            </Typography>
          )} */}
          {record.number_of_resources && record.number_of_resources !== 0 && (
            <Typography>
              <PersonIcon />
              {record.number_of_resources} {record.number_of_resources > 1 ? 'Positions' : 'Position'}
            </Typography>
          )}
        </Box>
      </CardContent>
    </>
  );
};
