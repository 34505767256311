import axios from 'axios';
import { Dispatch } from 'redux';
import { getPracticeAreasAction, practiceAreaErrorAction } from '../actions/PracticeAreaActions';

const api = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_OPPORTUNITY_DESIGNER_UI_SERVICE,
    headers: { 'Content-Type': 'application/json' },
  });
})();

export const getPracticeAreas = () => async (dispatch: Dispatch) => {
  try {
    const res = await api.get('/routing/data_service/practice_areas?filter[status]=active&per_page=infinity');
    dispatch(getPracticeAreasAction(res.data.records));
  } catch (error: any) {
    dispatch(practiceAreaErrorAction(error));
  }
};
