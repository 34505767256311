export const Statuses = {
  DRAFT: 'draft',
  IN_HIRING_WORKFLOW: 'in_hiring_workflow',
  IN_APPROVAL_WORKFLOW: 'in_approval_workflow',
  OPEN: 'open',
  PENDING_APPROVAL: 'pending_approval',
  ON_HOLD: 'on_hold',
  CLOSED: 'closed',
  DECLINED: 'declined',
};
