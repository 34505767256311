import { Typography, Card } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { UsePagination } from '../common/Pagination';
import { IPublicPage } from '../../interfaces/IPublicPage';
import { getOpportunities } from '../../services/OpportunitiesApi';
import { useStyles } from '../../styles/styles';
import { Project } from './Project';

export const Projects = ({ ...props }) => {
  const { reloadParams, handlePageChange, loading } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectsMetaData = useSelector((state: RootState) => state.opportunities.opportunitiesMetaData);

  useEffect(() => {
    if (reloadParams.filters !== undefined) dispatch(getOpportunities(reloadParams, true));
  }, [dispatch, reloadParams]);

  return (
    <>
      {(reloadParams.filters !== '' || reloadParams.keywords?.length > 0) && !loading && (
        <Typography variant='h4'>{`${projectsMetaData?.meta?.page?.total} ${projectsMetaData?.meta?.page?.total > 1 ? 'rows' : 'row'}  matching your criteria`}</Typography>
      )}

      {projectsMetaData?.records &&
        projectsMetaData?.records.map((row: IPublicPage, index: number) => (
          <Card className={classes.jobCard} key={index}>
            <Project record={row} reloadParams={reloadParams} />
          </Card>
        ))}
      <UsePagination
        count={projectsMetaData?.meta?.page?.total ? Math.ceil(projectsMetaData?.meta?.page?.total / projectsMetaData?.meta?.page?.per_page) : 0}
        page={projectsMetaData?.meta?.page?.page}
        handlePageChange={handlePageChange}
      ></UsePagination>
    </>
  );
};
