import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';

export const GET_OPPORTUNITIES_DATA = 'GET_OPPORTUNITIES_DATA';
export const OPPORTUNITY_ERRORS = 'OPPORTUNITY_ERRORS';

export const getOpportunitiesAction = (payload: IPublicPageMeta) => {
  return { type: GET_OPPORTUNITIES_DATA, payload };
};

export const opportunityErrorAction = (errors: any) => {
  return { type: OPPORTUNITY_ERRORS, errors };
};
