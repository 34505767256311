import { IPracticeArea } from '../interfaces/IPracticeArea';

export const GET_PRACTICE_AREAS_DATA = 'GET_PRACTICE_AREAS_DATA';
export const GET_PRACTICE_AREA_ERRORS = 'GET_PRACTICE_AREA_ERRORS';

export const getPracticeAreasAction = (payload: IPracticeArea[]) => {
  return { type: GET_PRACTICE_AREAS_DATA, payload };
};

export const practiceAreaErrorAction = (errors: any) => {
  return { type: GET_PRACTICE_AREA_ERRORS, errors };
};
