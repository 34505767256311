import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';
import { Dispatch } from 'redux';
import qs from 'qs';
import { talentApi } from './BaseApi';
import { setLoadingAction } from '../actions/LoaderActions';
import { IFilter } from '../interfaces/IFIlter';
import { getTalentCloudsAction, talentCloudErrorAction } from '../actions/TalentCloudsActions';
import { IPublicPage } from '../interfaces/IPublicPage';
import { parseObject } from '../utils/ParseObject';

export const getTalentClouds = (searchFilters: IFilter) => async (dispatch: Dispatch) => {
  dispatch(setLoadingAction(true));
  let { filters, keywords, page } = searchFilters;
  const url = filters ? `/talent_clouds/public_talentclouds?${filters}&page=${page}` : `/talent_clouds/public_talentclouds?page=${page}`;
  try {
    let response: IPublicPageMeta;
    if (keywords?.length > 0) {
      response = await talentApi.get(url, {
        params: {
          keywords,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
    } else {
      response = await talentApi.get(url);
    }

    dispatch(getTalentCloudsAction({ records: response.data.records.map((a: IPublicPage) => parseObject(a)), meta: response.data.meta }));
  } catch (error: any) {
    dispatch(talentCloudErrorAction(error));
  }
  dispatch(setLoadingAction(false));
};
