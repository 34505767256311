import { Box, Grid, SelectChangeEvent } from '@mui/material';
import { TextField, Select, MenuItem } from '@mui/material';
import { useStyles } from '../../../styles/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import FormLocation from '../FormLocation';
import { countries, radiusOptions } from '../../../constants/FiltersConstants';

export const BasicFilters = ({ ...props }) => {
  const { searchFilters, setSearchFilters, keywords, setKeywords, country, setCountry, location, setLocation, selectedRadius, setSelectedRadius, addFilterTags, removeFilterTags } = props;
  const classes = useStyles();
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');
  const [points, setPoints] = useState<{ lat: string; long: string }>({ lat: '', long: '' });

  useEffect(() => {
    if (keywords.length === 0) {
      setSelectedKeyword('');
      removeFilterTags('keyword');
    } else {
      setSelectedKeyword(keywords[0]);
      addFilterTags('keyword', keywords[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  useEffect(() => {
    if (country !== '') {
      addFilterTags('preferred_country', countries[country]);
    } else {
      removeFilterTags('preferred_country');
      let newSearchFilters = searchFilters;
      delete newSearchFilters['preferred_country'];
      newSearchFilters = { ...newSearchFilters };
      setSearchFilters(newSearchFilters);
      setLocation('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (location !== '') {
      addFilterTags('ideal_candidate_location', location);
    } else {
      removeFilterTags('ideal_candidate_location');
      let newSearchFilters = searchFilters;
      delete newSearchFilters['ideal_candidate_location'];
      newSearchFilters = { ...newSearchFilters };
      setSearchFilters(newSearchFilters);
      setSelectedRadius('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (selectedRadius !== '') {
      const radiusObj = radiusOptions.find((r) => r.id === selectedRadius);
      if (radiusObj) addFilterTags('location_coordinates', radiusObj.name);
    } else {
      removeFilterTags('location_coordinates');
      let newSearchFilters = searchFilters;
      delete newSearchFilters['location_coordinates'];
      newSearchFilters = { ...newSearchFilters };
      setSearchFilters(newSearchFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadius]);

  const handleKywordChange = (e: any) => {
    const keyword = e.target.value;
    if (keyword) {
      setSelectedKeyword(keyword);
    } else {
      setSelectedKeyword('');
    }
  };

  const onHandleKeywordsChange = (e: any) => {
    const keyword = e.target.defaultValue;
    if (e.code === 'Enter' && keyword !== '') {
      setSelectedKeyword(keyword);
      setKeywords([keyword]);
    }
  };

  const onCountryChange = (code: string, country: any) => {
    setCountry(code);
    let newSearchFilters = searchFilters;
    newSearchFilters = {
      ...newSearchFilters,
      preferred_country: country,
    };

    setSearchFilters(newSearchFilters);
  };

  const onCityChange = (place: any) => {
    setLocation(place.formatted_address);
    if (place.geometry && place.geometry.location) {
      const loc = place.geometry.location;
      setPoints({ lat: loc.lat(), long: loc.lng() });
    }

    let newSearchFilters = searchFilters;
    newSearchFilters = {
      ...newSearchFilters,
      ideal_candidate_location: place.formatted_address,
    };

    setSearchFilters(newSearchFilters);
  };

  const handleRadiusChange = (e: SelectChangeEvent) => {
    const radius = e.target.value;
    let newSearchFilters = searchFilters;
    if (radius && points.lat && points.long) {
      setSelectedRadius(radius);
      newSearchFilters = {
        ...newSearchFilters,
        location_coordinates: {
          distance: radius,
          points,
        },
      };
    }
    setSearchFilters(newSearchFilters);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.mainFiltersInline}>
          <TextField fullWidth variant='outlined' value={selectedKeyword} placeholder='Add job titles, Keyword' onChange={(e) => handleKywordChange(e)} onKeyDown={onHandleKeywordsChange} />
          <FormLocation onSelectCountry={onCountryChange} country={country} onChangeLocation={onCityChange} location={location} inForm={true} />

          <Select
            fullWidth
            value={selectedRadius}
            onChange={handleRadiusChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={KeyboardArrowDownIcon}
            className='for-range'
          >
            <MenuItem value=''>{'Radius'}</MenuItem>
            {radiusOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
    </>
  );
};
