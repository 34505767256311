import axios from 'axios';

export const oppApi = (() => {
  const base = process.env.REACT_APP_OPPORTUNITY_DESIGNER_UI_SERVICE;

  return axios.create({
    baseURL: base + '/external',
    headers: { 'Content-Type': 'application/json' },
  });
})();

export const talentApi = (() => {
  const base = process.env.REACT_APP_TALENT_CLOUD_UI_SERVICE;

  return axios.create({
    baseURL: base + '/external',
    headers: { 'Content-Type': 'application/json' },
  });
})();

export const accountApi = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_ACCOUNT_MANAGEMENT_UI_SERVICE,
    headers: { 'Content-Type': 'application/json' },
  });
})();
