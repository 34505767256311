import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, Slide, Typography, Stack, FormControl, TextField, Button } from '@mui/material';
import { useStyles } from '../../styles/styles';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formateFormErros, clearErros } from '../../helpers/Helpers';
import { FormAlert } from '../common/FormAlert';
import { verifyEmail } from '../../services/UserApi';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='left' ref={ref} {...props} />;
});

interface ApplyFormProps {
  identifier: string | undefined;
  openApplyForm: boolean;
  setOpenApplyForm: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export const ApplyForm = (props: ApplyFormProps) => {
  const { id } = useParams();
  const { identifier, openApplyForm, setOpenApplyForm } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    formateFormErros(formState, formErrors, setFormErrors, reset);
  }, [formErrors, formState, reset]);

  const registerOptions = {
    email: { required: 'Email is required' },
  };

  const handleVerifyEmail = async (data: any) => {
    const emailToVerify = {
      email: data.email,
      recordId: id,
      recordType: identifier,
    };

    dispatch(verifyEmail(emailToVerify));
  };

  const handleInput = (event: React.KeyboardEvent<HTMLElement>, error: string) => {
    clearErros(formErrors, setFormErrors, event.key !== '', error);
  };

  const handleClose = () => {
    setOpenApplyForm(false);
  };

  return (
    <>
      <Dialog
        open={openApplyForm}
        onClose={handleClose}
        aria-labelledby='apply-dialog-title'
        aria-describedby='apply-dialog-description'
        TransitionComponent={Transition}
        className={classes.applyDialog}
      >
        <DialogTitle id='apply-dialog-title'>
          <Typography variant='h3'>Apply for this job</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <div className='col--12-8'>
              <FormAlert errors={formErrors}></FormAlert>
            </div>
            <FormControl fullWidth className={classes.formTextInput}>
              <Typography variant='body1'>Email</Typography>
              <TextField
                {...register('email', registerOptions.email)}
                fullWidth
                variant='outlined'
                placeholder='Enter your Email'
                onKeyUp={(e: React.KeyboardEvent<HTMLElement>) => handleInput(e, formErrors?.filter((e: string) => e === 'Email is required')[0])}
              />
            </FormControl>
            <Button variant='contained' color='primary' onClick={handleSubmit(handleVerifyEmail)}>
              Continue
            </Button>
          </Stack>

          <Box className={classes.applyDialogSignature}>
            <Typography>Powered by Experfy</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
