import { combineReducers } from 'redux';
import { SectionReducer, State as SectionState } from './SectionReducer';
import { PracticeAreaReducer, State as PracticeAreaState } from './PracticeAreaReducer';
import { PublicPageReducer, State as PublicPageState } from './PublicPageReducer';
import { OpportunityReducer, State as OpportunityState } from './OpportunityReducer';
import { LoaderReducer, State as LoaderState } from './LoaderReducer';
import { UsersReducer, State as UsersState } from './UsersReducer';
import { CompaniesReducer, State as CompaniesState } from './CompaniesReducer';
import { TalentCloudsReducer, State as TalentCloudsState } from './TalentCloudsReducer';
import { WorkflowReducer, State as WorkflowState } from './WorkflowReducer';

export interface RootState {
  sections: SectionState;
  practiceAreas: PracticeAreaState;
  publicPages: PublicPageState;
  opportunities: OpportunityState;
  loader: LoaderState;
  users: UsersState;
  companies: CompaniesState;
  talentClouds: TalentCloudsState;
  workflows: WorkflowState;
}

export const rootReducer = combineReducers<RootState>({
  sections: SectionReducer,
  practiceAreas: PracticeAreaReducer,
  publicPages: PublicPageReducer,
  opportunities: OpportunityReducer,
  loader: LoaderReducer,
  users: UsersReducer,
  companies: CompaniesReducer,
  talentClouds: TalentCloudsReducer,
  workflows: WorkflowReducer,
});
