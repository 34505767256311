import { IPublicPage } from '../interfaces/IPublicPage';

export const GET_DATA_BY_ID = 'GET_DATA_BY_ID';
export const API_ERRORS = 'API_ERRORS';

export const getDataByIdAction = (payload: IPublicPage) => {
  return { type: GET_DATA_BY_ID, payload };
};

export const apiErrorAction = (errors: any) => {
  return { type: API_ERRORS, errors };
};
