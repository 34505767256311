import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import PracticeAreaMenuIcon from '../../assets/images/navbar_menu.svg';
import AIMachineLearningIcon from '../../assets/images/practice_areas/ai_machine_learning.svg';
import AIMachineLearningIconActive from '../../assets/images/practice_areas/ai_machine_learning_active.svg';
import BigDataIcon from '../../assets/images/practice_areas/big_data.svg';
import BigDataIconActive from '../../assets/images/practice_areas/big_data_active.svg';
import BusinessIntelligenceIcon from '../../assets/images/practice_areas/business_intelligence.svg';
import BusinessIntelligenceIconActive from '../../assets/images/practice_areas/business_intelligence_active.svg';
import CloudComputingIcon from '../../assets/images/practice_areas/cloud_computing.svg';
import CloudComputingIconActive from '../../assets/images/practice_areas/cloud_computing_active.svg';
import CyberSecurityIcon from '../../assets/images/practice_areas/cyber_security.svg';
import CyberSecurityIconActive from '../../assets/images/practice_areas/cyber_security_active.svg';
import DevOpsIcon from '../../assets/images/practice_areas/devops.svg';
import DevOpsIconActive from '../../assets/images/practice_areas/devops_active.svg';
import SoftwareWebDevelopmentIcon from '../../assets/images/practice_areas/software.svg';
import SoftwareWebDevelopmentIconActive from '../../assets/images/practice_areas/software_active.svg';
import QAIcon from '../../assets/images/practice_areas/qa.svg';
import QAIconActive from '../../assets/images/practice_areas/qa_active.svg';
import MarketingIcon from '../../assets/images/practice_areas/marketing.svg';
import MarketingIconActive from '../../assets/images/practice_areas/marketing_active.svg';
import UXUIDesignIcon from '../../assets/images/practice_areas/ux_ui_design.svg';
import UXUIDesignIconActive from '../../assets/images/practice_areas/ux_ui_design_active.svg';
import MobileIcon from '../../assets/images/practice_areas/mobile.svg';
import MobileIconActive from '../../assets/images/practice_areas/mobile_active.svg';
import RoboticProcessAutomationIcon from '../../assets/images/practice_areas/robotics.svg';
import RoboticProcessAutomationIconActive from '../../assets/images/practice_areas/robotics_active.svg';
import ServiceNowIcon from '../../assets/images/practice_areas/servicenow.svg';
import ServiceNowIconActive from '../../assets/images/practice_areas/servicenow_active.svg';
import FinTechIcon from '../../assets/images/practice_areas/fintech.svg';
import FinTechIconActive from '../../assets/images/practice_areas/fintech_active.svg';
import HealthcareIcon from '../../assets/images/practice_areas/healthcare.svg';
import HealthcareIconActive from '../../assets/images/practice_areas/healthcare_active.svg';
import PharmaBiotechIcon from '../../assets/images/practice_areas/pharma.svg';
import PharmaBiotechIconActive from '../../assets/images/practice_areas/pharma_active.svg';
import InternetOfThingsIcon from '../../assets/images/practice_areas/iot.svg';
import InternetOfThingsIconActive from '../../assets/images/practice_areas/iot_active.svg';
import ManufacturingIcon from '../../assets/images/practice_areas/manufacturing.svg';
import ManufacturingIconActive from '../../assets/images/practice_areas/manufacturing_active.svg';
import TelecomIcon from '../../assets/images/practice_areas/telecom.svg';
import TelecomIconActive from '../../assets/images/practice_areas/telecom_active.svg';
import OilGasIcon from '../../assets/images/practice_areas/oil.svg';
import OilGasIconActive from '../../assets/images/practice_areas/oil_active.svg';

const useStyles = makeStyles({
  navbarPracticeAreasDropdown: {
    marginRight: '2.5rem',
    '& .MuiButtonBase-root': {
      position: 'relative',
      height: '100%',
      color: '#ffffff !important',
      fontSize: '.875rem !important',
      fontWeight: 600,
      letterSpacing: '-.1px',
      textTransform: 'uppercase',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      borderRadius: '0 !important',
      '&[aria-expanded="true"]': {
        backgroundColor: '#ffffff',
        color: '#4ba4da !important',
      }
    },
  }, 
  navbarPracticeAreasDropdownIcon: {
    left: '1rem',
    width: '1.125rem',
    height: '1rem',
    marginRight: '.5rem',
    backgroundImage: `url(${PracticeAreaMenuIcon})`,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  navbarPracticeAreasDropdownMenu: {
    '& .MuiPaper-root': {
      borderRadius: '0 !important',
      '& .MuiList-root': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '776px !important',
        padding: '1rem .25rem',
      },
      '& .MuiMenuItem-root': {
        width: '7.5rem',
        minHeight: '7.125rem',
        display: 'inline-block',
        margin: '.25rem .25rem',
        padding: '0 !important',
        whiteSpace: 'normal !important',
        '& .MuiLink-root': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          height: '100%',
          width: '100%',
          padding: '1.125rem .25rem .5rem',
          backgroundColor: '#f8f8f8',
          border: 'none',
          borderRadius: '.25rem',
          whiteSpace: 'normal',
          fontSize: '.75rem',
          lineHeight: '1rem',
          textAlign: 'center',
          color: '#213558',
          transition: 'all .2s',
          '&::before': {
            content: '""',
            display: 'inline-block',
            width: '3rem',
            height: '3rem',
            marginBottom: '.5rem',
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          },
          '&:hover': {
            color: '#fff',
            backgroundColor: '#45A6EF',
          },
          '&.pa--ai-machine-learning::before': {
            backgroundImage: `url(${AIMachineLearningIcon})`,
          },
          '&.pa--ai-machine-learning:hover::before': {
            backgroundImage: `url(${AIMachineLearningIconActive})`,
          },
          '&.pa--big-data::before': {
            backgroundImage: `url(${BigDataIcon})`,
          },
          '&.pa--big-data:hover::before': {
            backgroundImage: `url(${BigDataIconActive})`,
          },
          '&.pa--business-intelligence::before': {
            backgroundImage: `url(${BusinessIntelligenceIcon})`,
          },
          '&.pa--business-intelligence:hover::before': {
            backgroundImage: `url(${BusinessIntelligenceIconActive})`,
          },
          '&.pa--cloud-computing::before': {
            backgroundImage: `url(${CloudComputingIcon})`,
          },
          '&.pa--cloud-computing:hover::before': {
            backgroundImage: `url(${CloudComputingIconActive})`,
          },
          '&.pa--cyber-security::before': {
            backgroundImage: `url(${CyberSecurityIcon})`,
          },
          '&.pa--cyber-security:hover::before': {
            backgroundImage: `url(${CyberSecurityIconActive})`,
          },
          '&.pa--devops::before': {
            backgroundImage: `url(${DevOpsIcon})`,
          },
          '&.pa--devops:hover::before': {
            backgroundImage: `url(${DevOpsIconActive})`,
          },
          '&.pa--software::before': {
            backgroundImage: `url(${SoftwareWebDevelopmentIcon})`,
          },
          '&.pa--software:hover::before': {
            backgroundImage: `url(${SoftwareWebDevelopmentIconActive})`,
          },
          '&.pa--qa::before': {
            backgroundImage: `url(${QAIcon})`,
          },
          '&.pa--qa:hover::before': {
            backgroundImage: `url(${QAIconActive})`,
          },
          '&.pa--marketing::before': {
            backgroundImage: `url(${MarketingIcon})`,
          },
          '&.pa--marketing:hover::before': {
            backgroundImage: `url(${MarketingIconActive})`,
          },
          '&.pa--ux-ui::before': {
            backgroundImage: `url(${UXUIDesignIcon})`,
          },
          '&.pa--ux-ui:hover::before': {
            backgroundImage: `url(${UXUIDesignIconActive})`,
          },
          '&.pa--mobile::before': {
            backgroundImage: `url(${MobileIcon})`,
          },
          '&.pa--mobile:hover::before': {
            backgroundImage: `url(${MobileIconActive})`,
          },
          '&.pa--rpa::before': {
            backgroundImage: `url(${RoboticProcessAutomationIcon})`,
          },
          '&.pa--rpa:hover::before': {
            backgroundImage: `url(${RoboticProcessAutomationIconActive})`,
          },
          '&.pa--servicenow::before': {
            backgroundImage: `url(${ServiceNowIcon})`,
          },
          '&.pa--servicenow:hover::before': {
            backgroundImage: `url(${ServiceNowIconActive})`,
          },
          '&.pa--fintech::before': {
            backgroundImage: `url(${FinTechIcon})`,
          },
          '&.pa--fintech:hover::before': {
            backgroundImage: `url(${FinTechIconActive})`,
          },
          '&.pa--healthcare::before': {
            backgroundImage: `url(${HealthcareIcon})`,
          },
          '&.pa--healthcare:hover::before': {
            backgroundImage: `url(${HealthcareIconActive})`,
          },
          '&.pa--pharma::before': {
            backgroundImage: `url(${PharmaBiotechIcon})`,
          },
          '&.pa--pharma:hover::before': {
            backgroundImage: `url(${PharmaBiotechIconActive})`,
          },
          '&.pa--iot::before': {
            backgroundImage: `url(${InternetOfThingsIcon})`,
          },
          '&.pa--iot:hover::before': {
            backgroundImage: `url(${InternetOfThingsIconActive})`,
          },
          '&.pa--manufacturing::before': {
            backgroundImage: `url(${ManufacturingIcon})`,
          },
          '&.pa--manufacturing:hover::before': {
            backgroundImage: `url(${ManufacturingIconActive})`,
          },
          '&.pa--telecom::before': {
            backgroundImage: `url(${TelecomIcon})`,
          },
          '&.pa--telecom:hover::before': {
            backgroundImage: `url(${TelecomIconActive})`,
          },
          '&.pa--oil::before': {
            backgroundImage: `url(${OilGasIcon})`,
          },
          '&.pa--oil:hover::before': {
            backgroundImage: `url(${OilGasIconActive})`,
          },
        }  
      } 
    } 
  }
});  

function PracticeAreaDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.navbarPracticeAreasDropdown}>
      <Button
        id="PracticeAreaLink"
        aria-controls={open ? 'PracticeAreaDropdown' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <span className={classes.navbarPracticeAreasDropdownIcon}></span>
        Practice Areas
      </Button>
      <Menu 
        id="PracticeAreaDropdown"
        className={classes.navbarPracticeAreasDropdownMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'PracticeAreaDropdown',
        }}
      >
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/ai-machine-learning" className='pa--ai-machine-learning'>AI and Machine Learning</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/big-data-management" className='pa--big-data'>Big Data</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/business-intelligence" className='pa--business-intelligence'>Busisness Intelligence</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/cloud-computing" className='pa--cloud-computing'>Cloud Computing</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/cyber-security" className='pa--cyber-security'>Cyber Security</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/devops" className='pa--devops'>DevOps</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/software-web-development" className='pa--software'>Software/Web Development</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/quality-assurance" className='pa--qa'>QA</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/marketing" className='pa--marketing'>Marketing</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/ux-ui-design" className='pa--ux-ui'>UX/UI Design</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/mobile" className='pa--mobile'>Mobile</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/robotic-process-automation" className='pa--rpa'>Robotic Process Automation</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/service-now" className='pa--servicenow'>ServiceNow</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/fintech" className='pa--fintech'>FinTech</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/healthcare" className='pa--healthcare'>Healthcare</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/pharma" className='pa--pharma'>Pharma and Biotech</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/internet-of-things" className='pa--iot'>Internet of Things</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/manufacturing-operations-and-supply-chain" className='pa--manufacturing'>Manufacturing, Operations and Supply Chain</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/telecom" className='pa--telecom'>Telecom</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/hire/oil-and-gas" className='pa--oil'>Oil and Gas</LinkTag>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default PracticeAreaDropdown;