import React, { useEffect, useRef } from 'react';
import { Box, FormControl, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from 'react-google-autocomplete';
import ReactFlagsSelect from 'react-flags-select';
import { countries } from '../../constants/FiltersConstants';

const useStyles = makeStyles({
  formLocationInput: {
    '& > p': {
      display: 'inline-block',
      fontSize: '1rem',
      fontWeight: 500,
      margin: '0 0 .125rem',
      color: '#4a5162',
    },
  },
  formLocationGroup: {
    display: 'flex',
    '&.in-rows': {
      flexDirection: 'column',
      '& > div:first-child': {
        width: '100% !important',
      },
      '& #rfs-btn': {
        borderRadius: '.25rem !important',
        marginBottom: '.25rem',
      },
      '& input': {
        borderRadius: '.25rem !important',
      },
    },
    '&.in-form': {
      '& > div:first-child': {
        width: '18.75rem',
      },
      '& #rfs-btn': {
        borderRadius: '0 !important',
      },
      '& > input': {
        borderRadius: '0',
        flex: 1,
      },
    },
    '@media (max-width: 768px)': {
      '&.in-form': {
        flexDirection: 'column',
        '& > div:first-child': {
          width: '100% !important',
        },
        '& #rfs-btn': {
          borderRadius: '.25rem !important',
          marginBottom: '.5rem',
        },
        '& input': {
          borderRadius: '.25rem !important',
        },
        '& > div': {
          '& > button': {
            borderRadius: '.25rem .25rem',
          },
        },
        '& > input': {
          borderRadius: '.25rem !important',
        },
      },
    },
  },
  formLocationCoutry: {
    width: '22.5rem',
    paddingBottom: '0 !important',
    '& button': {
      fontSize: '1rem !important',
      color: '#4a5162 !important',
      backgroundColor: '#fff !important',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
      borderRadius: '.25rem 0 0 .25rem !important',
      height: '3.5rem',
      '& svg': {
        width: '2rem !important',
        height: '2rem !important',
      },
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87) !important',
      },
      '&:focus': {
        borderColor: '#4ba4da !important',
        borderWidth: '2px !important',
        padding: '4px 9px',
      },
    },
    '& ul': {
      marginTop: '0 !important',
      borderRadius: '0 0 .25rem .25rem !important',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
  },
  formLocationAutocomplete: {
    flex: '1',
    fontFamily: 'inherit',
    color: 'inherit',
    fontSize: '1rem',
    lineHeight: '1.375',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: '0 0.25rem .25rem 0',
    backgroundColor: '#fff',
    padding: '1rem 1.125rem',
    width: '100%',
    outline: '0',
    height: '3.5rem',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus': {
      borderColor: '#4ba4da',
      borderWidth: '2px',
      padding: '.9375rem 1.0625rem',
    },
  },
  formLocationInvalid: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#e3795b',
    borderRadius: '.25rem !important',
    '&.in-rows': {
      flexDirection: 'column',
      '& > div:first-child': {
        width: '100% !important',
      },
      '& #rfs-btn': {
        borderRadius: '.25rem !important',
        marginBottom: '.25rem',
      },
      '& input': {
        borderRadius: '.25rem !important',
      },
    },
  },
});

interface FormLocationInterface {
  onSelectCountry: (code: string, country: any) => void;
  onChangeLocation?: (location: { formatted_address: string }) => void;
  country?: string;
  location?: string;
  label?: string;
  fullWidth?: boolean;
  inRows?: boolean;
  inValid?: boolean;
  inForm?: boolean;
}

function FormLocation({ onChangeLocation, onSelectCountry, fullWidth = false, inRows = false, label, country, location, inValid, inForm = false }: FormLocationInterface) {
  const classes = useStyles();
  const googleMapsApiKey = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const locationRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (country) onSelectCountry(country, countries[country]);
    if (locationRef.current) {
      locationRef.current.value = location || '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, country]);

  const onChangeCountry = (code: string) => {
    onSelectCountry(code, countries[code]);
  };

  return (
    <FormControl fullWidth={!fullWidth} className={classes.formLocationInput}>
      {label && <Typography variant='body1'>{label}</Typography>}
      <Box
        className={`
        ${inValid ? classes.formLocationInvalid : classes.formLocationGroup} 
        ${inRows ? 'in-rows' : ''}
        ${inForm ? 'in-form' : ''}`}
      >
        <ReactFlagsSelect
          searchable={true}
          className={classes.formLocationCoutry}
          selected={country || ''}
          onSelect={(code) => {
            onChangeCountry(code);
          }}
        />
        <Autocomplete
          className={classes.formLocationAutocomplete}
          apiKey={googleMapsApiKey}
          ref={locationRef}
          onPlaceSelected={(place, inputRef, autocomplete) => {
            onChangeLocation && onChangeLocation(place);
          }}
          options={{
            types: ['geocode'],
            componentRestrictions: { country },
            fields: ['geometry', 'address_components', 'formatted_address'],
          }}
        />
      </Box>
    </FormControl>
  );
}

export default FormLocation;
