import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import NavbarCaretIcon from '../../assets/images/navbar_caret.svg';

const useStyles = makeStyles({
  navbarDropdown: {
    marginRight: '0',
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '100%',
      color: '#ffffff !important',
      fontSize: '.875rem !important',
      fontWeight: 600,
      letterSpacing: '-.1px',
      textTransform: 'uppercase',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      borderRadius: '0 !important',
      minWidth: 'auto !important',
      '& > span:first-child': {
        display: 'inline-block',
        marginLeft: '.375rem',
        width: '.75rem',
        height: '.75rem',
        backgroundImage: `url(${NavbarCaretIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },  
      '&[aria-expanded="true"]': {
        backgroundColor: '#ffffff',
        color: '#4ba4da !important',
      }
    },
  }, 
  navbarDropdownMenu: {
    '& .MuiPaper-root': {
      borderRadius: '0 0 .25rem .25rem !important',
      padding: '.5rem 0px',
      minWidth: '13rem',
      '& .MuiMenuItem-root': {
        margin: '0rem',
        padding: '0 !important',
        whiteSpace: 'normal !important',
        '& .MuiLink-root': {
          display: 'block',
          fontSize: '.875rem !important',
          fontWeight: 600,
          padding: '0px 10px 0px 10px',
          lineHeight: '35px',
          transition: 'all .2s',
          color: '#4ba4da !important',
          '&:hover': {
            backgroundColor: '#f1f3f5',
            color: '#4ba4da !important',
          },
        }  
      } 
    } 
  }
});   

function ExpertDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.navbarDropdown}>
      <Button
        id="ExpertLink"
        aria-controls={open ? 'ExpertDropdown' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        For Expert
        <span></span>
      </Button>
      <Menu 
        id="ExpertDropdown"
        className={classes.navbarDropdownMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'ExpertDropdown',
        }}
      >
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://www.experfy.com/jobs">Expert Home</LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LinkTag href="https://account.experfy.com/signup/expert">Create a Profile</LinkTag>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default ExpertDropdown;