import { AnyAction } from 'redux';
import * as actionTypes from '../actions/WorkflowActions';
import { IWorkflow } from '../interfaces/IWorkflow';

export type State = {
  readonly workflow: IWorkflow;
};

const initialState: State = {
  workflow: {} as IWorkflow,
};

export const WorkflowReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_WORKFLOW_BY_ID: {
      return {
        ...state,
        workflow: action.payload,
      };
    }

    default:
      return state;
  }
};
