import { AnyAction } from 'redux';
import * as actionTypes from '../actions/TalentCloudsActions';
import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';

export type State = {
  readonly talentCloudsMetaData: IPublicPageMeta;
  readonly talentCloudErrors: any;
};

const initialState: State = {
  talentCloudsMetaData: { records: [], meta: { page: { per_page: 0, page: 0, total: 0 } } },
  talentCloudErrors: [],
};

export const TalentCloudsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_TALENTCLOUDS_DATA: {
      return {
        ...state,
        talentCloudsMetaData: {
          ...action.payload,
          records: action.payload.records,
        },
      };
    }
    case actionTypes.TALENTCLOUD_ERRORS: {
      return {
        ...state,
        talentCloudErrors: action.errors,
      };
    }
    default:
      return state;
  }
};
