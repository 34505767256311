import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { configureStore, history } from './store';
import App from './App';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_MEASUREMENT_ID}`);

export default function AppContainer() {
  const store = configureStore();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history} store={store}>
        <Router>
          <App />
        </Router>
      </ConnectedRouter>
    </Provider>
  );
}
