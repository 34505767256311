import { Constants } from '../utils/Constants';

export function formateBudgetRange(remunerations, min_rate, max_rate) {
  if (remunerations === Constants.emunerations.titles.HourlyRate) {
    if (max_rate) {
      return `$${max_rate}/hr`;
    } else {
      return `$${min_rate}/hr`;
    }
  } else {
    if (min_rate && max_rate) {
      return `$${min_rate} - $${max_rate}`;
    } else if (min_rate && !max_rate) {
      return `$${min_rate}`;
    } else if (!min_rate && max_rate) {
      return `$${max_rate}`;
    }
  }

  return '';
}

export function truncate(str = '') {
  return str.length > Constants.truncate.LIMIT ? str.substring(0, 3) + '...' : str;
}

export function formateFormErros(formState, formErrors, setFormErrors, reset) {
  if (formState.isSubmitted === true) {
    var updatedFormErrors = [...formErrors];
    if (Object.keys(formState.errors).length > 0) {
      Object.values(formState.errors).map((error) => {
        if (error && !updatedFormErrors.includes(error.message)) {
          updatedFormErrors = [...updatedFormErrors, error.message];
        }
        return updatedFormErrors;
      });
    }
    setFormErrors(updatedFormErrors);
    reset();
  }
}

export function clearErros(formErrors, setFormErrors, value, error) {
  if (formErrors.length > 0 && value === true) {
    var updatedFormErrors = [...formErrors];
    var index = updatedFormErrors.indexOf(error);
    if (index !== -1) {
      updatedFormErrors.splice(index, 1);
      setFormErrors(updatedFormErrors);
    }
  }
}

export function getUserShortName(fullNameArray) {
  if (!fullNameArray?.length) return '';
  return fullNameArray
    .compact()
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
}

export function profilePicture(fileName, fallback = true) {
  if (!fileName || fileName === '') {
    const fallbackUrl = 'https://blogs.3ds.com/northamerica/wp-content/uploads/sites/4/2019/08/Robots-Square-300x300.jpg';

    return fallback ? fallbackUrl : null;
  }

  return fileName.toLowerCase().includes('http') ? fileName : `${process.env.REACT_APP_FILE_STORAGE_SERVICE}/show/${fileName}`;
}

export function getAccountType(type) {
  if (!type) return '';

  if (type === 'site_admin') return ' an Admin ';
  if (type === 'experfy_staff_user') return ' an Experfy Staff ';
  if (type === 'tod_client') return ' a Client ';
  if (type === 'expert') return ' an Expert ';
  if (type === 'guest_user') return ' a Guest ';

  return 'an Experfy';
}

export function capitalName(text) {
  return text && text !== '' ? text.charAt(0).toUpperCase() + text.slice(1) : '';
}
