import * as React from 'react';
import { Box, Button, Menu, MenuItem, Grid, Typography } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import NavbarCaretIcon from '../../assets/images/navbar_caret.svg';

const useStyles = makeStyles({
  navbarDropdown: {
    marginRight: '0',
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '100%',
      color: '#ffffff !important',
      fontSize: '.875rem !important',
      fontWeight: 600,
      letterSpacing: '-.1px',
      textTransform: 'uppercase',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      borderRadius: '0 !important',
      minWidth: 'auto !important',
      '& > span:first-child': {
        display: 'inline-block',
        marginLeft: '.375rem',
        width: '.75rem',
        height: '.75rem',
        backgroundImage: `url(${NavbarCaretIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '&[aria-expanded="true"]': {
        backgroundColor: '#ffffff',
        color: '#4ba4da !important',
      },
    },
  },
  navbarSolutionsDropdownMenu: {
    '& .MuiPaper-root': {
      borderRadius: '0px 0px 0.25rem 0.25rem !important',
      padding: '.5rem 1rem',
      width: '850px',
      marginLeft: '-200px !important',
      '& .MuiMenuItem-root': {
        padding: '0.0625rem 0 !important',
        '& .MuiLink-root': {
          fontSize: '.875rem !important',
          fontWeight: 600,
          '&.is-shifted': {
            marginLeft: '.75rem',
          },
        },
      },
    },
  },
  navbarDropdownTitle: {
    color: '#555 !important',
    fontSize: '1rem !important',
    fontWeight: 600,
    lineHeight: '1.2',
    marginBottom: '.375rem !important',
  },
  navbarDropdownText: {
    fontSize: '.875rem !important',
    marginBottom: '.625rem !important',
  },
});

function SolutionsDropdown() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.navbarDropdown}>
      <Button id='SolutionsLink' aria-controls={open ? 'SolutionsDropdown' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        Solutions
        <span></span>
      </Button>
      <Menu
        id='SolutionsDropdown'
        className={classes.navbarSolutionsDropdownMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'SolutionsDropdown',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={4} p={2}>
            <Typography variant='h4' className={classes.navbarDropdownTitle}>
              FUTURE OF WORK PLATFORM
            </Typography>
            <Typography variant='body1' className={classes.navbarDropdownText}>
              Build powerful engine for verticalized recruiting.
            </Typography>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/saas'>Experfy Talent Acquisition Platform</LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/crm' className='is-shifted'>
                Talent Stream
              </LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/ats' className='is-shifted'>
                Talent Recruit
              </LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/analytics' className='is-shifted'>
                Recruiting and Pipelining Analytics
              </LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/career-studio' className='is-shifted'>
                Career Studio (coming soon)
              </LinkTag>
            </MenuItem>
            <Typography variant='h4' className={classes.navbarDropdownTitle} mt={2}>
              COMPARE OFFERINGS
            </Typography>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/pricing' className='is-shifted'>
                3 Ways to Get You Started
              </LinkTag>
            </MenuItem>
          </Grid>
          <Grid item xs={4} p={2}>
            <Typography variant='h4' className={classes.navbarDropdownTitle}>
              UPSKILLING PLATFORM
            </Typography>
            <Typography variant='body1' className={classes.navbarDropdownText}>
              Self-paced courses, mentorship and certifications to turn your employees into industry experts and leaders.
            </Typography>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://training.experfy.com' target='_blank'>
                Training Home
              </LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://training.experfy.com/courses' className='is-shifted' target='_blank'>
                Browse Courses
              </LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://training.experfy.com/instructors' className='is-shifted' target='_blank'>
                Become an Instructor
              </LinkTag>
            </MenuItem>
          </Grid>
          <Grid item xs={4} p={2}>
            <Typography variant='h4' className={classes.navbarDropdownTitle}>
              EXPERFY TALENTCLOUDS
            </Typography>
            <Typography variant='body1' className={classes.navbarDropdownText}>
              Skill-specific pools of pipelined talent that's pre-vetted by our SMEs for fast and frictionless access
            </Typography>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/clients/experfy-talentclouds'>Browse TalentClouds</LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/why-experfy'>Why Experfy?</LinkTag>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://talent.experfy.com/opportunities/opportunity/job-details'>Hire Expert</LinkTag>
            </MenuItem>
            <Typography variant='h4' className={classes.navbarDropdownTitle} mt={2}>
              CUSTOM TALENTCLOUDS
            </Typography>
            <MenuItem onClick={handleClose}>
              <LinkTag href='https://www.experfy.com/contact'>Talk to Us</LinkTag>
            </MenuItem>
          </Grid>
        </Grid>
      </Menu>
    </Box>
  );
}

export default SolutionsDropdown;
