import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../../styles/styles';

export const PromoCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContentFooter}>
      <Box className={classes.mainContentFooterInner}>
        <Typography variant='h3'>Join our Expert Community</Typography>
        <Typography variant='body1'>Join our exclusive Expert Community to get invitations to job opportunities and contract projects directly from hiring managers at top companies.</Typography>
        <Button variant='contained' color='primary' href='https://account.experfy.com/choose-account'>
          Join Now
        </Button>
      </Box>
    </Box>
  );
};
