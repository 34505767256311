import { IStage } from '../interfaces/IStage';
import { IStageGroup } from '../interfaces/IStagrGroup';
import { IWorkflow } from '../interfaces/IWorkflow';

export const getWorkflowStageById = (workflows: IWorkflow[], stageId: string): IStage | undefined => {
  const stageGroups = workflows.map((workflow) => workflow.stage_groups).flat();
  if (stageGroups && stageGroups.length > 0) {
    const stages = stageGroups.map((sg) => sg.stages).flat();
    if (stages && stageId && stages.length > 0) {
      const workflowStage = stages.find((stage) => stage.id === stageId);
      return workflowStage;
    }
  }
  return undefined;
};

const sortStageGroups = (stageGroups: IStageGroup[]): IStageGroup[] => {
  return stageGroups.sort((a, b) => a.display_order - b.display_order);
};

export const firstStageFor = (workflow: IWorkflow | undefined): IStage | undefined => {
  if (!workflow) return undefined;
  workflow.stage_groups = sortStageGroups(workflow.stage_groups);
  const stageGroup = workflow?.stage_groups.find((sg) => sg.stage_group_type === 'applied');
  const fallbackStageGroup = stageGroup ? stageGroup : workflow?.stage_groups[0];
  return stageGroup?.stages[0].stage_type === 'applied' ? stageGroup?.stages[0] : fallbackStageGroup?.stages[0];
};

export const findStageByStageId = (workflow: IWorkflow, stageId: string): IStage | undefined => {
  if (!Object.keys(workflow).length) return undefined;
  workflow.stage_groups = sortStageGroups(workflow.stage_groups);

  const stages = workflow.stage_groups
    ? workflow.stage_groups
        .flat()
        .map((sg) => sg.stages)
        .flat()
    : [];
  return stages.find((stage) => stage.id === stageId);
};

export const findStageByStageType = (workflow: IWorkflow, type: string): IStage | undefined => {
  if (!Object.keys(workflow).length) return undefined;
  workflow.stage_groups = sortStageGroups(workflow.stage_groups);
  const stages = workflow.stage_groups
    ? workflow.stage_groups
        .flat()
        .map((sg) => sg.stages)
        .flat()
    : [];
  const stage = stages.find((stage) => stage.stage_type === type);
  return stage ? stage : stages[stages.length - 1];
};
