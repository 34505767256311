import { Grid, SelectChangeEvent } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import { useStyles } from '../../../styles/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { dateRange } from '../../../utils/DateUtil';
import { hourlyRateOptions, jobTypesOptions, postedDateOptions, rateRangeOptions, remunerationsOptions } from '../../../constants/FiltersConstants';
import { getPracticeAreas } from '../../../services/PracticeAreaApi';

export const AdvanceFilters = ({ ...props }) => {
  const {
    searchFilters,
    setSearchFilters,
    selectedPracticeArea,
    setSelectedPracticeArea,
    selectedWorkType,
    setSelectedWorkType,
    selectedPostDate,
    setSelectedPostDate,
    selectedRemuneration,
    setSelectedRemuneration,
    selectedRateRange,
    setSelectedRateRange,
    addFilterTags,
    removeFilterTags,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const practiceAreas = useSelector((state: RootState) => state.practiceAreas.practiceAreasData);
  const [showRate, setShowRate] = useState(false);

  useEffect(() => {
    dispatch(getPracticeAreas());
  }, [dispatch]);

  useEffect(() => {
    const practicArea = practiceAreas.find((p) => p._id === selectedPracticeArea);
    if (practicArea) {
      addFilterTags('practice_areas', practicArea.title);
    } else {
      removeFilterTags('practice_areas');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPracticeArea, practiceAreas]);

  useEffect(() => {
    if (selectedWorkType !== '') {
      addFilterTags('work_type', selectedWorkType);
    } else {
      removeFilterTags('work_type');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkType]);

  useEffect(() => {
    if (selectedPostDate !== 'any-date') {
      const date = postedDateOptions.find((d) => d.id === selectedPostDate);
      addFilterTags('created_at', date ? date.name : selectedPostDate);
    } else {
      removeFilterTags('created_at');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPostDate]);

  useEffect(() => {
    setShowRate(selectedRemuneration !== '');
    if (selectedRemuneration) {
      addFilterTags('compensation_type', selectedRemuneration);
    } else {
      removeFilterTags('compensation_type');
    }
    setSelectedRateRange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRemuneration]);

  useEffect(() => {
    const range = selectedRemuneration === 'Hourly Rate' ? hourlyRateOptions.find((opt) => opt.id === selectedRateRange) : rateRangeOptions.find((opt) => opt.id === selectedRateRange);
    if (range) {
      addFilterTags('range', range.name);
    } else {
      removeFilterTags('range');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRateRange]);

  const handlePracticeAreaChange = (e: SelectChangeEvent) => {
    setSelectedPracticeArea(e.target.value);
    let newSearchFilters = searchFilters;
    const practicArea = practiceAreas.find((p) => p._id === e.target.value);
    if (practicArea) {
      newSearchFilters = { ...newSearchFilters, practice_areas: e.target.value };
    } else {
      delete newSearchFilters['practice_areas'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleWorkTypeChange = (e: SelectChangeEvent) => {
    setSelectedWorkType(e.target.value);
    let newSearchFilters = searchFilters;
    if (e.target.value) {
      newSearchFilters = { ...newSearchFilters, work_type: e.target.value };
    } else {
      delete newSearchFilters['work_type'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  /**
   * If input === any_date, we remove created_date filter to fetch opportunities/ TC with any date
   */
  const handlePostDateChange = (e: SelectChangeEvent) => {
    const input = e.target.value;
    setSelectedPostDate(input);
    let newSearchFilters = searchFilters;
    if (input !== 'any-date') {
      let createdDateRange = null;
      if (input === 'today') {
        createdDateRange = new Date().toISOString();
      } else {
        createdDateRange = dateRange(parseInt(input));
      }
      newSearchFilters = { ...newSearchFilters, created_at: createdDateRange };
    } else {
      delete newSearchFilters['created_at'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleRemunerationChange = (e: SelectChangeEvent) => {
    const remuneration = e.target.value;
    setSelectedRemuneration(remuneration);

    let newSearchFilters = searchFilters;
    if (remuneration) {
      newSearchFilters = {
        ...newSearchFilters,
        compensation_type: remuneration,
      };

      delete newSearchFilters['max_rate'];
      delete newSearchFilters['min_rate'];
      newSearchFilters = { ...newSearchFilters };
    } else {
      delete newSearchFilters['compensation_type'];
      delete newSearchFilters['max_rate'];
      delete newSearchFilters['min_rate'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  const handleRateRangeChange = (isHourly: boolean, e: any) => {
    const input = e.target.value;
    setSelectedRateRange(input);
    const range = isHourly ? hourlyRateOptions.find((opt) => opt.id === input) : rateRangeOptions.find((opt) => opt.id === input);

    let newSearchFilters = searchFilters;
    if (range) {
      newSearchFilters = {
        ...newSearchFilters,
        compensation_type: selectedRemuneration,
        max_rate: range.value.max_rate,
        min_rate: range.value.min_rate,
      };
    } else {
      delete newSearchFilters['max_rate'];
      delete newSearchFilters['min_rate'];
      newSearchFilters = { ...newSearchFilters };
    }

    setSearchFilters(newSearchFilters);
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} mt={2}>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedPracticeArea} onChange={handlePracticeAreaChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value=''>{'Select Practice Area'}</MenuItem>
            {practiceAreas.length > 0 &&
              practiceAreas.map((option: any, index: number) => (
                <MenuItem className={classes.selected} id={option._id} value={option._id} key={index}>
                  {option.title}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedWorkType} onChange={handleWorkTypeChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value='' key={'all-0'}>
              {'All Job Types'}
            </MenuItem>
            {jobTypesOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedPostDate} onChange={handlePostDateChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            {postedDateOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={2} xs={12}>
          <Select fullWidth value={selectedRemuneration} onChange={handleRemunerationChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }} IconComponent={KeyboardArrowDownIcon}>
            <MenuItem value='' key={'c-0'}>
              {'Compensation'}
            </MenuItem>
            {remunerationsOptions.map((option: any, index: number) => (
              <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {showRate && (
          <>
            {selectedRemuneration === 'Hourly Rate' ? (
              <>
                <Grid item sm={2} xs={12}>
                  <Select
                    fullWidth
                    value={selectedRateRange}
                    onChange={(e) => handleRateRangeChange(true, e)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value='' key={'h-0'}>
                      {'$/hr'}
                    </MenuItem>
                    {hourlyRateOptions.map((option: any, index: number) => (
                      <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={2} xs={12}>
                  <Select
                    fullWidth
                    value={selectedRateRange}
                    onChange={(e) => handleRateRangeChange(false, e)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <MenuItem value='' key={'mn-0'}>
                      {'Min - Max'}
                    </MenuItem>
                    {rateRangeOptions.map((option: any, index: number) => (
                      <MenuItem className={classes.selected} id={option.id} value={option.id} key={index}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
