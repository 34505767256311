import { IPracticeArea } from './../interfaces/IPracticeArea';
import { AnyAction } from 'redux';
import * as actionTypes from '../actions/PracticeAreaActions';

export type State = {
  readonly practiceAreasData: IPracticeArea[];
  readonly practiceAreaErrors: any;
};

const initialState: State = {
  practiceAreasData: [],
  practiceAreaErrors: [],
};

export const PracticeAreaReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_PRACTICE_AREAS_DATA: {
      return {
        ...state,
        practiceAreasData: action.payload,
      };
    }
    case actionTypes.GET_PRACTICE_AREA_ERRORS: {
      return {
        ...state,
        practiceAreaErrors: action.errors,
      };
    }
    default:
      return state;
  }
};
