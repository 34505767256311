import { AnyAction } from 'redux';
import * as actionTypes from '../actions/CompanyActions';
import { ICompany } from '../interfaces/ICompany';

export type State = {
  readonly companyData: ICompany;
};

const initialState: State = {
  companyData: {} as ICompany,
};

export const CompaniesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_COMPANY_DATA: {
      return {
        ...state,
        companyData: action.payload,
      };
    }
    default:
      return state;
  }
};
