import { IPublicPage } from './../interfaces/IPublicPage';
import { ISection } from '../interfaces/ISection';
import { Dispatch } from 'redux';
import { getSectionAction } from '../actions/SectionActions';
import { apiErrorAction, getDataByIdAction } from '../actions/PublicPageActions';
import { parseObject, parseSection } from '../utils/ParseObject';
import { oppApi, talentApi } from './BaseApi';
import { setCompanyData } from '../actions/CompanyActions';

export const getDataById = (id: string | undefined, identifier: string | undefined) => async (dispatch: Dispatch) => {
  try {
    const res = identifier === 'opportunities' ? await oppApi.get(`/opportunities/${id}`) : await talentApi.get(`/talent_clouds/${id}`);
    const page: IPublicPage = parseObject(res.data);
    dispatch(getDataByIdAction(page));
    const sections: ISection = parseSection(page);
    dispatch(getSectionAction(sections));
  } catch (error: any) {
    dispatch(apiErrorAction(error));
  }
};

export const getCompany = (userId: string) => async (dispatch: Dispatch) => {
  const response = await oppApi.get(`/opportunities/company/${userId}`);
  dispatch(setCompanyData(response.data.records[0]));
};
