export function parseSection(obj: any) {
  return {
    expertise: !!obj.skill_levels || !!obj.educations || !!obj.certifications || !!obj.skills,
    work_arrangement: !!obj.job_type || !!obj.time_commitment || !!obj.preferred_working_hours,
    location: !!obj.ideal_candidate_location || !!obj.open_to_remote || !!obj.travel_requirement,
    compensation: !!obj.remunerations && (!!obj.min_rate || !!obj.max_rate),
  };
}

export function parseObject(obj: any) {
  return obj.job_title
    ? { ...obj }
    : {
        ...obj,
        job_title: obj.name,
        job_type: getJobType(obj),
        number_of_resources: obj.expected_hires !== 0 ? obj.expected_hires : null,
        skill_levels: obj.experience_level,
        time_commitment: getMinHours(obj),
        preferred_working_hours: getWorkingHours(obj),
        remunerations: obj.compensation_type,
        min_rate: obj.min_rate || obj.hourly_min_rate || obj.annual_min_salary,
        max_rate: obj.max_rate || obj.hourly_max_rate || obj.annual_max_salary,
      };
}

function getJobType(obj: any) {
  let jobType: any[] = [];
  if (obj.contractor) jobType = [...jobType, 'Contractor'];
  if (obj.part_time_available) jobType = [...jobType, 'Part-time Permanent'];
  if (obj.full_time_available) jobType = [...jobType, 'Full-time Permanent'];
  if (obj.any_time_preference) jobType = [...jobType, 'Flexible'];

  return jobType.join(', ');
}

function getMinHours(obj: any) {
  let minHours: any[] = [];
  if (obj.between_part_full_time_available) minHours = [...minHours, '0-20'];
  if (obj.part_time_preference) minHours = [...minHours, '20-40'];
  if (obj.full_time_preference) minHours = [...minHours, '40+'];

  return minHours.join(', ');
}

function getWorkingHours(obj: any) {
  let workingHours: any[] = [];
  if (obj.office_hours) workingHours = [...workingHours, 'Office Hours'];
  if (obj.outside_office_hours) workingHours = [...workingHours, 'Outside Office Hours'];
  if (obj.flexible) workingHours = [...workingHours, 'Flexible Hours'];

  return workingHours.join(' - ');
}
