export const Constants = {
  truncate: {
    LIMIT: 200,
  },
  emunerations: {
    titles: {
      HourlyRate: 'Hourly Rate',
      FixedPriceProject: 'FixedPriceProject',
      AnnualSalary: 'Annual Salary',
      MonthlySalary: 'MonthlySalary',
    },
    range_titles: {
      'Hourly Rate': 'Maximum Hourly Rate',
      'Fixed Price Project': 'Fixed Price Range',
      'Annual Salary': 'Annual Salary Range',
      'Monthly Salary': 'Monthly Salary Range',
    },
  },
};
