import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FormGroup, Stack, Switch, Typography } from '@mui/material';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#50ae81' : '#3ba773',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const useStyles = makeStyles({
  formSwitchLabel: {
    fontSize: '1.0625rem',
    lineHeight: '1.375',
  },
});

export default function FormSwitch({ ...props }) {
  const classes = useStyles();

  return (
    <FormGroup>
      <Stack direction='row' spacing={1} alignItems='center'>
        <AntSwitch checked={props.brandSwitch} onChange={props.handleSwitchChange} inputProps={{ 'aria-label': 'ant design' }} />

        {props.label && <Typography className={classes.formSwitchLabel}>{props.label}</Typography>}
      </Stack>
    </FormGroup>
  );
}
