import React, { useEffect } from 'react';
import { Box, Button, Menu, MenuItem, Avatar, Typography } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import NavbarCaretIcon from '../../assets/images/navbar_caret.svg';
import SettingsIcon from '../../assets/images/icon_settings.svg';
import ChatIcon from '../../assets/images/navbar/chat.svg';
import ChatIconActive from '../../assets/images/navbar/chat_light.svg';
import FavoritesIcon from '../../assets/images/navbar/favorites.svg';
import FavoritesIconActive from '../../assets/images/navbar/favorites_light.svg';
import LogoutIcon from '../../assets/images/navbar/logout.svg';
import LogoutIconActive from '../../assets/images/navbar/logout_light.svg';
import PricingIcon from '../../assets/images/navbar/pricing.svg';
import PricingIconActive from '../../assets/images/navbar/pricing_light.svg';
import ResourcesIcon from '../../assets/images/navbar/resources.svg';
import ResourcesIconActive from '../../assets/images/navbar/resources_light.svg';
import TeamIcon from '../../assets/images/navbar/team.svg';
import TeamIconActive from '../../assets/images/navbar/team_light.svg';
import WorkroomIcon from '../../assets/images/navbar/workroom.svg';
import WorkroomIconActive from '../../assets/images/navbar/workroom_light.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getAccountType, profilePicture } from '../../helpers/Helpers';
import { getCompany } from '../../services/PublicPagesApi';

const useStyles = makeStyles({
  navbarProfileDropdown: {
    marginRight: '0',
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '100%',
      color: '#ffffff !important',
      fontSize: '.875rem !important',
      fontWeight: 600,
      letterSpacing: '-.1px',
      paddingLeft: '0rem',
      paddingRight: '0rem',
      borderRadius: '0 !important',
      minWidth: 'auto !important',
      marginRight: '-.875rem',
      '& > span.dropdownCaret': {
        display: 'inline-block',
        marginLeft: '.375rem',
        width: '.75rem',
        height: '.75rem',
        backgroundImage: `url(${NavbarCaretIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '& .MuiAvatar-root': {
        marginLeft: '.5rem',
      },
      '&[aria-expanded="true"]': {
        '& > span.dropdownCaret': {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
  navbarProfileDropdownMenu: {
    '& .MuiPaper-root': {
      right: '0 !important',
      left: 'auto !important',
      borderRadius: '0 0 !important',
      padding: '0px',
      minWidth: '21rem',
      width: '21rem',
      '& .MuiList-root': {
        padding: '0px !important',
      },
    },
  },
  navbarProfileUserInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem 1rem 1rem',
    borderBottom: '1px solid #d1dbe3',
    minHeight: '5rem',
    '& .navbarProfileUserName': {
      display: 'inline-block',
      fontSize: '1rem !important',
      fontWeight: 600,
      lineHeight: '1.325',
    },
    '& .navbarProfileUserEmail': {
      display: 'inline-block',
      fontSize: '.9375rem !important',
      lineHeight: '1.325',
      marginTop: '.125rem',
    },
    '& .navbarProfileUserID': {
      display: 'inline-block',
      fontSize: '.9375rem !important',
      lineHeight: '1.325',
      marginTop: '.125rem',
    },
    '& .navbarProfileUserLink': {
      display: 'inline-block',
      fontSize: '1rem !important',
      fontWeight: 600,
      lineHeight: '1.325',
      marginTop: '.5rem',
    },
  },
  navbarProfileAccount: {
    padding: '1rem',
    borderBottom: '1px solid #d1dbe3',
    '& .MuiTypography-h5': {
      fontSize: '1rem !important',
      fontWeight: 600,
      lineHeight: '1.325',
      marginBottom: '1rem',
    },
    '& .MuiButtonBase-root': {
      width: '100%',
      fontSize: '1.0625rem',
      marginBottom: '1.5rem',
      '& .iconSettings': {
        display: 'inline-block',
        width: '1.25rem',
        height: '1.25rem',
        margin: '0 .5rem 0 0',
        backgroundImage: `url(${SettingsIcon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '& .MuiLink-root': {
        color: '#fff !important',
      },
    },
  },
  navbarProfileAccountInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.9375rem',
    '& .navbarProfileAccountTitle': {
      width: '7.5rem',
    },
    '& .navbarProfileAccountValue': {
      flex: '1',
      fontWeight: '600',
      color: '#4a5162',
    },
    '&:last-child': {
      marginTop: '.5rem',
    },
  },
  navbarProfileDropdownItem: {
    borderBottom: '1px solid #d1dbe3 !important',
    padding: '0 !important',
    '& .MuiLink-root': {
      display: 'block !important',
      position: 'relative',
      padding: '.75rem 1.5rem .75rem 3rem !important',
      fontSize: '.9375rem !important',
      fontWeight: '400 !important',
      color: '#4a5162 !important',
      lineHeight: '1.325 !important',
      width: '100%',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '1rem',
        top: '50%',
        width: '1.25rem',
        height: '1.5rem',
        marginTop: '-.75rem',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '&.for-chat': {
        '&::before': {
          backgroundImage: `url(${ChatIcon})`,
        },
      },
      '&.for-favorites': {
        '&::before': {
          backgroundImage: `url(${FavoritesIcon})`,
        },
      },
      '&.for-rosters': {
        '&::before': {
          backgroundImage: `url(${FavoritesIcon})`,
        },
      },
      '&.for-logout': {
        '&::before': {
          backgroundImage: `url(${LogoutIcon})`,
        },
      },
      '&.for-pricing': {
        '&::before': {
          backgroundImage: `url(${PricingIcon})`,
        },
      },
      '&.for-resources': {
        '&::before': {
          backgroundImage: `url(${ResourcesIcon})`,
        },
      },
      '&.for-team': {
        '&::before': {
          backgroundImage: `url(${TeamIcon})`,
        },
      },
      '&.for-workroom': {
        '&::before': {
          backgroundImage: `url(${WorkroomIcon})`,
        },
      },
      '&:focus': {
        color: '#fff !important',
        backgroundColor: '#4ba4da !important',
        '&.for-chat': {
          '&::before': {
            backgroundImage: `url(${ChatIconActive})`,
          },
        },
        '&.for-favorites': {
          '&::before': {
            backgroundImage: `url(${FavoritesIconActive})`,
          },
        },
        '&.for-rosters': {
          '&::before': {
            backgroundImage: `url(${FavoritesIconActive})`,
          },
        },
        '&.for-logout': {
          '&::before': {
            backgroundImage: `url(${LogoutIconActive})`,
          },
        },
        '&.for-pricing': {
          '&::before': {
            backgroundImage: `url(${PricingIconActive})`,
          },
        },
        '&.for-resources': {
          '&::before': {
            backgroundImage: `url(${ResourcesIconActive})`,
          },
        },
        '&.for-team': {
          '&::before': {
            backgroundImage: `url(${TeamIconActive})`,
          },
        },
        '&.for-workroom': {
          '&::before': {
            backgroundImage: `url(${WorkroomIconActive})`,
          },
        },
      },
    },
  },
  navbarProfileLegacy: {
    padding: '1rem 0 0',
    '& .MuiTypography-h6': {
      fontSize: '.875rem !important',
      fontWeight: 600,
      lineHeight: '1.325',
      marginBottom: '1rem',
      padding: '0 1rem',
    },
  },
  navbarProfileDropdownLegacyItem: {
    padding: '0 !important',
    '& .MuiLink-root': {
      display: 'block !important',
      position: 'relative',
      padding: '.75rem 1rem .75rem 1rem !important',
      fontSize: '.9375rem !important',
      fontWeight: '400 !important',
      lineHeight: '1.325 !important',
      width: '100%',
    },
  },
});

function ProfileDropdown() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users.userDetails);
  const company = useSelector((state: RootState) => state.companies.companyData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (user?.id && user?.user_type === 'tod_client') {
      dispatch(getCompany(user.id));
    }
  }, [user, dispatch]);

  return (
    <Box className={classes.navbarProfileDropdown}>
      <Button id='ProfileLink' aria-controls={open ? 'ProfileDropdown' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        {`${user?.first_name} ${user?.last_name}`}
        <span className='dropdownCaret'></span>
        <Avatar src={profilePicture(user?.profiles?.profile_picture, false)} alt={`${user?.first_name} ${user?.last_name}`}></Avatar>
      </Button>
      <Menu
        id='ProfileDropdown'
        className={classes.navbarProfileDropdownMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'ProfileDropdown',
        }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={classes.navbarProfileUserInfo}>
          <span className='navbarProfileUserName'>{`${user?.first_name} ${user?.last_name}`}</span>
          <span className='navbarProfileUserEmail'>{`${user?.email}`}</span>
          <span className='navbarProfileUserID'>USER ID: {user?.id.replace(/\D/g, '').slice(0, 8)}</span>
          <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/account/settings`} className='navbarProfileUserLink'>
            {['expert', 'guest_user'].includes(user?.user_type) ? <>Personal Preferences</> : <>Profile and Preferences</>}
          </LinkTag>
        </Box>
        <Box className={classes.navbarProfileAccount}>
          {user?.user_type === 'tod_client' ? (
            <>
              <Typography variant='h5'>You are currently our Talent-On Demand Marketplace client</Typography>
              <Button variant='contained' color='success'>
                <span className='iconSettings'></span>
                <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/account/settings?page=Payments`}>Billing and Payments</LinkTag>
              </Button>
              {company?._id && (
                <>
                  <div className={classes.navbarProfileAccountInfo}>
                    <span className='navbarProfileAccountTitle'>Account:</span>
                    <span className='navbarProfileAccountValue'>{company?.account_name}</span>
                  </div>
                  <div className={classes.navbarProfileAccountInfo}>
                    <span className='navbarProfileAccountTitle'>Account ID:</span>
                    <span className='navbarProfileAccountValue'>{company?._id.replace(/\D/g, '').slice(0, 8)}</span>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant='h5'>
                You have
                {getAccountType(user?.user_type)}
                Account
              </Typography>
              <Button variant='contained' color='success'>
                <span className='iconSettings'></span>
                <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/account/settings?page=Payments`}>Account and Payments</LinkTag>
              </Button>
            </>
          )}
        </Box>
        <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
          <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/opportunities/messages`} className='for-chat'>
            Messages
          </LinkTag>
        </MenuItem>

        {user?.user_type === 'expert' ? (
          <>
            <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
              <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/expert/my-favorites`} className='for-favorites'>
                My Favorites
              </LinkTag>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
              <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/opportunities/rosters`} className='for-rosters'>
                My Rosters
              </LinkTag>
            </MenuItem>
          </>
        )}

        <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
          <LinkTag href={`${process.env.REACT_APP_FRONTEND_CORE_SERVICE}/workrooms`} className='for-workroom'>
            My Workrooms
          </LinkTag>
        </MenuItem>

        {!['expert', 'guest_user'].includes(user?.user_type) && (
          <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
            <LinkTag href='https://www.experfy.com/pricing' className='for-pricing' target='_blank'>
              Pricing & Features
            </LinkTag>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
          <LinkTag href='https://resources.experfy.com' className='for-resources' target='_blank'>
            Resources
          </LinkTag>
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownItem}>
          <LinkTag href={`${process.env.REACT_APP_LANDING_FRONTEND_SERVICE}/choose-account?signout=true`} className='for-logout'>
            Sign out
          </LinkTag>
        </MenuItem>

        {user?.user_type === 'expert' && (user?.imported_from === 'v1' || user?.imported_from === 'v2') && (
          <>
            <Box className={classes.navbarProfileLegacy}>
              <Typography variant='h6'>Access your past projects and training dashboards on legacy platform.</Typography>
            </Box>
            {user?.imported_from === 'v1' && (
              <>
                <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownLegacyItem}>
                  <LinkTag href='https://www.experfy.com/users/sign_in' target='_blank'>
                    Projects Dashboard
                  </LinkTag>
                </MenuItem>
                <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownLegacyItem}>
                  <LinkTag href='https://training.experfy.com' target='_blank'>
                    Training Platform
                  </LinkTag>
                </MenuItem>
              </>
            )}
            {user?.imported_from === 'v2' && (
              <>
                <MenuItem onClick={handleClose} className={classes.navbarProfileDropdownLegacyItem}>
                  <LinkTag href='https://app.experfy.com/users/sign_in' target='_blank'>
                    Deloitte TalentCloud
                  </LinkTag>
                </MenuItem>
              </>
            )}
          </>
        )}
      </Menu>
    </Box>
  );
}

export default ProfileDropdown;
