import { IUser } from '../interfaces/IUser';

export const SET_INDIVIDUAL_USER = 'SET_INDIVIDUAL_USER';
export const SET_USERS_DATA = 'SET_USERS_DATA';
export const SET_USER = 'SET_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const setUsersData = (payload: IUser[]) => {
  return { type: SET_USERS_DATA, payload };
};

export const setIndividualUser = (payload: IUser) => {
  return { type: SET_INDIVIDUAL_USER, payload };
};

export const setUserAction = (payload: any) => {
  return { type: SET_USER, payload };
};

export const setUserProfileAction = (payload: any) => {
  return { type: SET_USER_PROFILE, payload };
};
