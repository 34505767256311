import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';

export const GET_TALENTCLOUDS_DATA = 'GET_TALENTCLOUDS_DATA';
export const TALENTCLOUD_ERRORS = 'TALENTCLOUD_ERRORS';

export const getTalentCloudsAction = (payload: IPublicPageMeta) => {
  return { type: GET_TALENTCLOUDS_DATA, payload };
};

export const talentCloudErrorAction = (errors: any) => {
  return { type: TALENTCLOUD_ERRORS, errors };
};
