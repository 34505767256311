import { IPublicPage } from './../interfaces/IPublicPage';
import { AnyAction } from 'redux';
import * as actionTypes from '../actions/PublicPageActions';

export type State = {
  readonly byIdData: IPublicPage;
  readonly apiErrors: any;
};

const initialState: State = {
  byIdData: {} as IPublicPage,
  apiErrors: [],
};

export const PublicPageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_DATA_BY_ID: {
      return {
        ...state,
        byIdData: action.payload,
      };
    }
    case actionTypes.API_ERRORS: {
      return {
        ...state,
        apiErrors: action.errors,
      };
    }
    default:
      return state;
  }
};
