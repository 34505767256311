import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  pageLoader: {
    zIndex: '99999',
    position: 'fixed',
    top: '3.5rem',
    left: '3.5rem',
    right: '0',
    bottom: '0',
  },
  pageLoaderInner: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageLoaderContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageLoaderText: {
    fontSize: '1.125rem',
    fontWeight: '600',
    textAlign: 'center',
    margin: '1rem 0 0',
  },
});

function Loader() {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'} pt={2} pb={2} className={classes.pageLoader}>
      <Box className={classes.pageLoaderInner}>
        <Box className={classes.pageLoaderContent}>
          <svg className='whole' width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='app_loader'>
              <animateTransform
                xlinkHref='#app_loader'
                attributeName='transform'
                attributeType='XML'
                type='rotate'
                from='0 50 50'
                to='360 50 50'
                dur='1s'
                begin='0s'
                repeatCount='indefinite'
                restart='always'
              ></animateTransform>
              <path
                className='a'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50 90C72.0914 90 90 72.0914 90 50C90 27.9086 72.0914 10 50 10C27.9086 10 10 27.9086 10 50C10 72.0914 27.9086 90 50 90Z'
                fill='#d1dbe3'
              ></path>
              <path className='b' fillRule='evenodd' clipRule='evenodd' d='M100 50C100 22.3858 77.6142 0 50 0V10C72.0914 10 90 27.9086 90 50H100Z' fill='#4ba4da'></path>
            </g>
          </svg>
          <Typography variant='h4' className={classes.pageLoaderText}>
            Loading...
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default Loader;
