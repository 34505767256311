import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({ ...props }) {
  const { title } = props;

  return (
    <Helmet>
      <>
        <title>{title}</title>
      </>
    </Helmet>
  );
}
