import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export const UsePagination = (props: { count: number; page: number; handlePageChange: any }) => {
  const { count, page, handlePageChange } = props;

  return (
    <Stack spacing={2}>
      <Pagination size='large' count={count} page={page} siblingCount={1} boundaryCount={1} variant='outlined' shape='rounded' onChange={handlePageChange} />
    </Stack>
  );
};
