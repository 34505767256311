import { AnyAction } from 'redux';
import * as actionTypes from '../actions/OpportunitiesActions';
import { IPublicPageMeta } from '../interfaces/IPublicPageMeta';

export type State = {
  readonly opportunitiesMetaData: IPublicPageMeta;
  readonly opportunityErrors: any;
};

const initialState: State = {
  opportunitiesMetaData: { records: [], meta: { page: { per_page: 0, page: 0, total: 0 } } },
  opportunityErrors: [],
};

export const OpportunityReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_OPPORTUNITIES_DATA: {
      return {
        ...state,
        opportunitiesMetaData: {
          ...action.payload,
          records: action.payload.records,
        },
      };
    }
    case actionTypes.OPPORTUNITY_ERRORS: {
      return {
        ...state,
        opportunityErrors: action.errors,
      };
    }
    default:
      return state;
  }
};
