import React, { useEffect } from 'react';
import { Box, Stack, Button } from '@mui/material';
import LinkTag from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import LogoImage from '../../assets/images/experfy_logo.svg';
import { useNavigate } from 'react-router-dom';
import PracticeAreaDropdown from '../navbar/PracticeAreaDropdown';
import SolutionsDropdown from '../navbar/SolutionsDropdown';
import ClientDropdown from '../navbar/ClientDropdown';
import ExpertDropdown from '../navbar/ExpertDropdown';
import ResourceHubDropdown from '../navbar/ResourceHubDropdown';
import ProfileDropdown from '../navbar/ProfileDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { getUserProfile } from '../../services/UserApi';

const useStyles = makeStyles({
  nav: {
    display: 'flex',
    height: '3.5rem',
    backgroundColor: '#1b2c4a',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    zIndex: '1000',
    padding: '0 .625rem',
  },
  navBrand: {
    width: '12.5rem',
    height: '100%',
    padding: '0.75rem 0rem 0.5rem .625rem',
  },
  navContent: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    padding: '0 1.5rem 0 0.625rem',
    '& img': {
      display: 'block',
      height: '2.5rem',
      width: '2.5rem',
      borderRadius: '50%',
    },
  },
  navLinksItem: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.9375rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#f7f7f7 !important',
    cursor: 'pointer',
    padding: '0.125rem 0 0 0',
    marginRight: '2rem !important',
    '& svg': {
      fill: '#4ba4da',
      marginTop: '-.125rem',
    },
  },
  navButton: {
    height: '2.5rem !important',
    fontSize: '0.9375rem !important',
    backgroundColor: '#88437D !important',
    marginRight: '1.875rem !important',
    '&:hover': {
      backgroundColor: '#954989 !important',
    },
    '&:focus': {
      backgroundColor: '#954989 !important',
    },
  },
  navPlainLink: {
    display: 'inline-block',
    fontSize: '0.875rem !important',
    fontWeight: '600 !important',
    padding: '0 1.25rem',
  },
});

function Navbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const userProfile = useSelector((state: RootState) => state.users.userDetails);

  useEffect(() => {
    if (currentUser.id) {
      dispatch(getUserProfile(currentUser.id));
    }
  }, [currentUser, dispatch]);

  return (
    <nav className={classes.nav}>
      <Box className={classes.navBrand}>
        <LinkTag onClick={() => navigate(`/careers`)}>
          <img src={LogoImage} alt='Experfy' />
        </LinkTag>
      </Box>
      <Box className={classes.navContent}>
        <Stack direction='row'>
          <PracticeAreaDropdown />
          <Stack direction='row'>
            <SolutionsDropdown />
            <ClientDropdown />
            <ExpertDropdown />
            <ResourceHubDropdown />
          </Stack>
        </Stack>

        {userProfile?.id ? (
          <>
            <ProfileDropdown />
          </>
        ) : (
          <>
            <Stack
              direction='row'
              sx={{
                alignItems: 'center',
              }}
            >
              <Button variant='contained' className={classes.navButton} href='https://account.experfy.com/signup/expert'>
                I'm an Expert
              </Button>
              <LinkTag href='https://account.experfy.com/choose-account' className={classes.navPlainLink}>
                Sign Up
              </LinkTag>
              <LinkTag href='https://account.experfy.com/signin' className={classes.navPlainLink}>
                Login
              </LinkTag>
            </Stack>
          </>
        )}
      </Box>
    </nav>
  );
}

export default Navbar;
