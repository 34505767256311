import { ISection } from '../interfaces/ISection';
import { AnyAction } from 'redux';
import * as actionTypes from '../actions/SectionActions';

export type State = {
  readonly sectionData: ISection;
};

const initialState: State = {
  sectionData: {
    expertise: false,
    work_arrangement: false,
    location: false,
    compensation: false,
  },
};

export const SectionReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_SECTION: {
      return {
        ...state,
        sectionData: action.payload,
      };
    }
    default:
      return state;
  }
};
